@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'MiSans';
  src: url('./font/MiSans-Normal.woff2') format('woff2');
  // font-weight: normal;
}

@font-face {
  font-family: 'MiSans-bold';
  src: url('./font/MiSans-Medium.woff2') format('woff2');
  font-weight: bold;
}
@font-face {
  font-family: 'MiSans-bolder';
  src: url('./font/MiSans-Demibold.woff2') format('woff2');
  font-weight: bold;
}
// @font-face {
//   font-family: 'MiSans';
//   src: url('./font/MiSans-Bold.ttf') format('truetype');
//   font-weight: bolder;
// }

html,body {
  /* color: rgb(var(--foreground-rgb)); */
  color: #252525;
  background-color: #F2F3F5;
  font-family: "MiSans", "MiSans";
  font-weight: normal;
  margin: 0;
  padding: 0;
}

@media (min-width: 1200px) {
  /* 设置滚动条的宽度和高度 */
  ::-webkit-scrollbar {
    width: 16px; /* 垂直滚动条的宽度 */
    height: auto; /* 水平滚动条的高度 */
  }
  /* 设置滚动条滑块的样式 */
  ::-webkit-scrollbar-thumb {
    background-color: #26ADDF; /* 滚动条滑块的背景颜色 */
    border-radius: 10px; /* 滚动条滑块的圆角 */
    opacity: 0;
    // border: 3px solid #fff; /* 滚动条滑块的边框，增加滑块的间距 */
  }

  /* 设置滚动条滑块在鼠标悬停时的样式 */
  ::-webkit-scrollbar-thumb:hover {
    opacity: 1;
    background-color: #26ADDF; /* 鼠标悬停时滚动条滑块的背景颜色 */
  }

  /* 设置滚动条轨道的样式 */
  ::-webkit-scrollbar-track {
    background-color: rgba(38, 173, 223, 0.2); /* 滚动条轨道的背景颜色 */
    border-radius: 10px; /* 滚动条轨道的圆角 */
  }

  /* 设置滚动条按钮的样式 */
  ::-webkit-scrollbar-button {
    background-color: #888; /* 滚动条按钮的背景颜色 */
    // display: none; /* 隐藏滚动条按钮 */
  }
}

@media(max-width: 1200px) {
  /* 设置滚动条的宽度和高度 */
  ::-webkit-scrollbar {
    width: 6px; /* 垂直滚动条的宽度 */
    height: auto; /* 水平滚动条的高度 */
  }

  /* 设置滚动条滑块的样式 */
  ::-webkit-scrollbar-thumb {
    background-color: #26ADDF; /* 滚动条滑块的背景颜色 */
    border-radius: 3px; /* 滚动条滑块的圆角 */
    // border: 3px solid #fff; /* 滚动条滑块的边框，增加滑块的间距 */
  }

  /* 设置滚动条滑块在鼠标悬停时的样式 */
  ::-webkit-scrollbar-thumb:hover {
    background-color: #26ADDF; /* 鼠标悬停时滚动条滑块的背景颜色 */
  }

  /* 设置滚动条轨道的样式 */
  ::-webkit-scrollbar-track {
    background-color: rgba(38, 173, 223, 0.2); /* 滚动条轨道的背景颜色 */
    border-radius: 3px; /* 滚动条轨道的圆角 */
  }

  /* 设置滚动条按钮的样式 */
  ::-webkit-scrollbar-button {
    background-color: #888; /* 滚动条按钮的背景颜色 */
    // display: none; /* 隐藏滚动条按钮 */
  }
}


.btn {
  position: relative;
  cursor: pointer;
  line-height: normal;
  &::before {
    background: #fff3 !important;
    bottom: 0;
    content: "";
    left: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
    transition: opacity .15s ease;
    border-radius: 20px;
  }
  img {
    transition: all .15s ease;
  }
  &:hover {
    line-height: normal;
    &::before {
      opacity: 1;
    }
    transition: all .15s ease;
    img {
      margin-left: 10px;
      transition: all .15s ease;
    }
  }
}

.nav-active {
  color: #ffffff;
}
.nav-hover-con{
  position: relative;
  &::before {
    position: absolute;
    content: "";
    top: -10px;
    // background-color: white;
    left: 100px; /* 调整三角形的位置 */
    transform: translateY(-50%);
    border-width: 10px 10px 10px 10px;
    border-style: solid;
    overflow: hidden;
    border-color: transparent  transparent #fff transparent;
  }
}

// 行业新闻位置平行四边行css
.industry-location {
  position: relative;
  overflow: hidden;
  transform: skew(-15deg);
  margin-left: 2px;
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: -110px;
    width: 100%;
    height: 100%;
    background: inherit;
    transform: skew(15deg);
  }
  span {
    transform: skew(15deg);
  }
  img {
    transform: skew(15deg);
  }
}

//hover时下方进度条显示效果
.line-progress {
  background-size: 100% 2px,0 2px;
  background-position: 100% 100%,0 100%;
  background-repeat: no-repeat;
  text-decoration: none;
  background-image: linear-gradient(transparent, transparent),linear-gradient(270deg, #26ADDF 0%, #26ADDF 100%);
  cursor: pointer;
  &:hover {
    // color: #1b1078;
    color: #26ADDF;
    background-size: 0 2px,100% 2px;
    background-position: 100% 100%,0 100%;
    transition: all 0.2s ease-out;
  }
}
.line-progress-active{
  background-repeat: no-repeat;
  text-decoration: none;
  background-image: linear-gradient(transparent, transparent),linear-gradient(270deg, #26ADDF 0%, #26ADDF 100%);
  cursor: pointer;
    // color: #1b1078;
  color: #26ADDF;
  background-size: 0 2px,100% 2px;
  background-position: 100% 100%,0 100%;
}

.swiper-button-prev,.swiper-button-next {
  background-color: rgba(38, 173, 223, 0.5);
  cursor: pointer;
  // background-image: url('./imgs/icons/swiper-arrow.png');
  // background-position: center;
  // background-size: contain;
  // background-repeat: no-repeat;
  img {
    margin-left: 0;
    transition: all .1s ease;
    margin-right: 0;
  }
  &:hover {
    background-color: #26addf;
    transition: all .1s ease;
  }
}
.swiper-button-prev{
  &:hover {
    img {
      transition: all .1s ease;
      margin-left: -5px;
    }
  } 
}
.swiper-button-next{
  img {
    transform: rotate(180deg);
  }
  &:hover {
    img {
      transition: all .1s ease;
      margin-right: -5px;
    }
  } 
}
.swiperSlide-active {
  // transition: ;
  border-radius: 16px;
  img {
    scale: 1.05;
    border: 2px solid rgba(38, 173, 223, 1);
    // box-shadow: 0px 0px 20px 0px rgba(77,169,255,0.4);
  }
}


.newsPaginationBar {
  //分页pagination样式
  @media (min-width: 900px) {
    .ant-pagination {
      display: flex;
      justify-content: center;
      align-items: center;
      li{
        margin-right: 10px;
        text-align: center;
        font-size: 18px ;
        color: #818181 ;
        line-height: normal;
        border-radius: 5px;
        // padding: 6px 10px !important;
        box-shadow: 0px 0px 8px 0px rgba(77,169,255,0.5);
        background: #ffffff ;
        a {
          position: relative;
          top: 6px;
          color: #818181;
          // margin-top: 5px;
        }
      }
      .ant-pagination-prev,.ant-pagination-next {
        // box-shadow: 0px 0px 8px 0px rgba(77,169,255,0.5);
        background: #ffffff;
        // border-radius: 5px;
        padding: 0 10px;
        height: 36px;
        &:hover {
          background: #F6CF73;
          a{
            color: #fff;
          }
        }
        // line-height: 36px;
        a{
          position: relative;
          top: 5px;
        }
        // line-height: normal;
        // color: #818181;
        // font-size: 18px;
        
        // display: flex;
        // align-items: center;
        // justify-content: center;
      }
      .ant-pagination-item,.ant-pagination-jump-next,.ant-pagination-jump-prev{
        width: 36px;
        height: 36px;
        &:hover{
          background-color: #F6CF73;
          a {
            color: #fff !important;
          }
        }
        a{
          padding: 0 !important;
        }
        // .ant-pagination-item-link{
        //   justify-content: center !important;
        //   align-items: center !important;
        // }
      }
      .ant-pagination-jump-next,.ant-pagination-jump-prev {
        &:hover {
          .ant-pagination-item-link-icon{
            color: #fff !important;
          }
          a {
            top: 6px !important;
          }
        }
        a{
          top: 8px !important;
        }
      }
      .ant-pagination-item-active{
        background: #F6CF73 !important;
        color: white !important;
        border: none;
        a{
          top: 7px !important;
          color: white !important;
  
        }
      }
    }
  }
  @media (max-width: 900px) {
    .ant-pagination {
      display: flex;
      justify-content: center;
      align-items: center;
      li{
        font-size: 12px ;
        color: #818181 ;
        line-height: normal;
        border-radius: 5px;
        // padding: 6px 10px !important;
        box-shadow: 0px 0px 8px 0px rgba(77,169,255,0.5);
        background: #ffffff ;
        a {
          position: relative;
          top: 6px;
          color: #818181;
          // margin-top: 5px;
        }
      }
      .ant-pagination-prev,.ant-pagination-next {
        // box-shadow: 0px 0px 8px 0px rgba(77,169,255,0.5);
        background: #ffffff;
        // border-radius: 5px;
        padding: 0 5px;
        // height: 18px;
        &:hover {
          background: #F6CF73;
          a{
            color: #fff;
          }
        }
        // line-height: 36px;
        a{
          position: relative;
          top: 5px;
        }
        // line-height: normal;
        // color: #818181;
        // font-size: 18px;
        
        // display: flex;
        // align-items: center;
        // justify-content: center;
      }
      .ant-pagination-item{
        // width: 18px;
        // height: 18px;
        &:hover{
          background-color: #F6CF73;
          a {
            color: #fff !important;
          }
        }
        a{
          padding: 0 !important;
        }
      }
      .ant-pagination-item-active{
        background: #F6CF73 !important;
        color: white !important;
        border: none;
        a{
          top: 7px !important;
          color: white !important;
  
        }
      }
    }
  }
}
.peoplePaginationBar{
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  // padding: 0 16px;
  box-sizing: border-box;
  @media (min-width:900px) {
    .ant-pagination {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      height: 40px;
      .ant-pagination-item{
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background: transparent;
        border: none;
        font-size: 16px;
        color: #30323B;
        line-height: 40px;
        text-align: center;
        &:hover{
          background-color: #F6CF73;
          color: #fff;
        }
      }
      .ant-pagination-jump-next {
        &:hover {
          .ant-pagination-item-link-icon {
            // background-color: #F6CF73;
            color: #F6CF73 !important;
          }
        }
      }
      .ant-pagination-jump-prev {
        &:hover {
          .ant-pagination-item-link-icon {
            // background-color: #F6CF73;
            color: #F6CF73 !important;
          }
        }
      }
      .ant-pagination-item-active{
        background-color: #F6CF73;
        color: #fff;
      }
      .ant-pagination-prev {
        position: absolute;
        top: 0;
        left: 0;
      }
      .ant-pagination-next {
        position: absolute;
        top: 0;
        right: 0;
      }
    }
  }
  @media (max-width: 900px) {
    .ant-pagination {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      height: 24px !important;
      .ant-pagination-item{
        width: 24px !important;
        height: 24px !important;
        min-width: 24px !important;
        border-radius: 50%;
        background: transparent;
        border: none;
        font-size: 12px;
        color: #30323B;
        line-height: 24px;
        text-align: center;
        &:hover{
          background-color: #F6CF73;
          color: #fff;
        }
      }
      .ant-pagination-jump-next {
        &:hover {
          .ant-pagination-item-link-icon {
            // background-color: #F6CF73;
            color: #F6CF73 !important;
          }
        }
      }
      .ant-pagination-jump-prev {
        &:hover {
          .ant-pagination-item-link-icon {
            // background-color: #F6CF73;
            color: #F6CF73 !important;
          }
        }
      }
      .ant-pagination-item-active{
        background-color: #F6CF73;
        color: #fff;
      }
      .ant-pagination-prev {
        position: absolute;
        top: 0;
        left: 0;
      }
      .ant-pagination-next {
        position: absolute;
        top: 0;
        right: 0;
      }
    }
  }
}
.talkPaginationBar {
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  // padding: 0 16px;
  box-sizing: border-box;
  .ant-pagination {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    height: 40px;
    .ant-pagination-item{
      width: 40px;
      height: 40px;
      // border-radius: 50%;
      background: #fff;
      border: none;
      font-size: 16px;
      color: #818181;
      line-height: 40px;
      text-align: center;
      &:hover{
        background-color: #F6CF73 !important;
        color: #fff;
      }
    }
    .ant-pagination-jump-next {
      &:hover {
        .ant-pagination-item-link-icon {
          // background-color: #F6CF73;
          color: #F6CF73 !important;
        }
      }
    }
    .ant-pagination-jump-prev {
      &:hover {
        .ant-pagination-item-link-icon {
          // background-color: #F6CF73;
          color: #F6CF73 !important;
        }
      }
    }
    .ant-pagination-item-active{
      background-color: #F6CF73;
      color: #fff;
    }
    // .ant-pagination-prev {
    //   position: absolute;
    //   // top: 0;
    //   left: 0;
    // }
    // .ant-pagination-next li{
    //   position: absolute;
    //   // top: 0;
    //   right: 0;
    // }
  }
}